@import "../../../vars.scss";

.toggleButtonContainer {
    display: flex;
    pointer-events: all;
    outline: none;
    cursor: pointer;
    color: $bright_grey;
    transition-duration: 200ms;

    &:hover {
        color: $dark_grey;
    }

    .toggleButtonTitle {
        flex: 1;
    }

    .toggleButton {
        position: relative;
        width: 44px;
        height: 24px;
        background-color: $color_border;
        border-radius: 12px;
        border: solid 2px $color_border;
        box-sizing: border-box;
        overflow: hidden;
        transition: background-color 0.5s ease;

        &:hover {
            border-color: $color_accent;
        }

        .dot {
            content: "";
            width: 20px;
            height: 20px;
            background-color: $white;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
            border-radius: 50%;
            transition-duration: 200ms;

            &.selected {
                transition-delay: 50ms;
                transform: translateX(100%);
            }
        }

        &.selected {
            background-color: $color_accent;
        }
    }

    .selected {
        border-color: $color_accent;
        color: $dark_grey;
        font-weight: 600;
    }
}
