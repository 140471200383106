@import "../../vars.scss";

@include for-phone-only {
    .mapActionsContainer {
        display: none;
    }
}

.mapActionsContainer {
    position: absolute;
    left: $spacing_s;
    top: 50%;
    width: 160px;
    transform: translateY(-50%);
    
    .mapActionsBox {
        width: 100%;
        padding: 1.6rem;
        border-radius: 8px;
        background-color: $color_menu;
        box-shadow: 0 2px 12px 1px rgba(0, 0, 0, 0.4);
        border: solid 1px rgba(0, 0, 0, 0.2);

        p {
            font-size: 1.4rem;
            margin: 0 0 1rem;
            padding-bottom: 0.5rem;
            border-bottom: solid 0.5px rgba(0, 0, 0, 0.2);
        }

        .actionsRow {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        & + .mapActionsBox {
            margin-top: $spacing_l;
        }
    }
}