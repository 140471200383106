@import "../../vars.scss";

#loginpage {
    // .top_wrapper{
    //     margin-top:0;
    // }

    @include for-phone-up {
        .buttons_wrapper {
            width: 100%;
            margin-left: auto;
            text-align: right;
            padding-top: $spacing_m;
            > *:last-child {
                margin-left: $spacing_s;
            }
        }
    }
    @include for-phone-only {
        .buttons_wrapper {
            padding-top: $spacing_m;
            padding-bottom: $spacing_xs;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column-reverse;
            justify-content: space-between;
            .button.inverted {
                margin-top: 1rem;
            }
        }
    }

    .oem-checkbox {
        font-size: 1.4rem;
        font-weight: bold;
        .link {
            font-weight: $font_weight_bold;
        }
    }
    .terms-checkbox {
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    .oem-info {
        max-height: 0rem;
        overflow: hidden;
        transition: all 0.15s;
        &.expanded {
            max-height: 18rem;
        }
        p {
            font-size: 1.4rem;
            color: #7a8999;
            padding-bottom: 0;
        }
    }

    .region-info {
        font-size: 1.4rem;
        color: $gray;
        font-weight: bold;
        padding-top: $spacing_s;
        padding-bottom: $spacing_s;

        &::before {
            content: "i";
            // display: inline-block;
            line-height: 1.4rem;
            padding-top: 3px;
            float: left;
            color: $color_accent;
            font-weight: bold;
            border: 2px solid $color_accent;
            border-radius: 50%;
            width: 23px;
            height: 23px;
            text-align: center;
            padding-left: 0px;
            margin-top: 0.6rem;
            margin-right: $spacing_xs;
        }
    }
    .done-email {
        font-weight: $font_weight_bold;
        color: $color_dark;
        padding-top: 1rem;
        padding-bottom: $spacing_s;
    }
}
