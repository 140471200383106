@import "../../vars.scss";

.mapComponentRoot {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1A2227;
}

.mapMarker {
    position: relative;
    width: 24px;
    height: 24px;
    padding: 6px;
    border-radius: 50%;
    box-shadow: 0 4px 12px 8px rgba(0, 0, 0, 0.24);
    background-color: rgba(67, 81, 250, 0.6);

    .dot {
        position: absolute;
        width: 12px;
        height: 12px;
        border: solid 1px rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        background-color: $color_accent;
    }
}

.ol-attribution {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 1.5rem;

    a {
        color: unset;
        
    }

    ul {
        margin: 0;
        list-style-type: none;
    }

    button {
        background-image: none;
        background-color: transparent;
        width: unset;
        display: none;
    }
  }