@import "../../vars.scss";


.sidebarContainer {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 320px;
    background-color: $color_menu;
    color: $black;
    padding: $spacing_xl $spacing_s $spacing_l;
    box-shadow: 0 -2px 12px 8px rgba(0, 0, 0, 0.4);
    pointer-events: all;
    display: flex;
    flex-direction: column;
    z-index: $z-index_header;

    .closeIcon {
        position: absolute;
        top: $spacing_s;
        right: $spacing_s;
        cursor: pointer;
    }

    .logoWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;
    }
}

@include for-phone-only {
    .sidebarContainer {
        width: 80vw;
    }
}