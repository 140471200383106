@import "../../vars.scss";

.cookiebar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;

    &.hidden {
        transition: bottom 750ms ease-in-out;
        bottom: -25rem;
    }


    >div {
        display: flex;
        justify-content: space-between;
        max-width: 68rem;
        padding: $spacing_s $spacing_l;
        background-color: white;
        border: 1px solid $color_accent;
        border-radius: 8px;

        @include for-phone-only {
            flex-direction: column;
            margin-bottom: 0;
            .button {
                margin-top: 2rem;
                width: 100%;
            }
        }

        @include for-phone-up {
            margin-left: auto;
            margin-right: 2rem;
            margin-bottom: 1rem;
            .actions {
                font-size: 1.4rem;
                margin-left: $spacing_s;
                flex-shrink: 0;
                align-self: flex-end;
            }
        }

    }



    &.visible {
        animation: moveUp 4s;
    }

    @keyframes moveUp {
        0% {
            bottom: -23rem;
        }
        75% {
            bottom: -23rem;
        }
        100% {
            bottom: 0;
        }
    }
}
