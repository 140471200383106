@import "vars.scss";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

*, ::after, ::before {
    box-sizing: border-box;
}

html, body {
    font-family: Lato, Tahoma, Geneva, Verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $color_background;
    color: $color_body;
    font-weight: normal;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}
html {
    font-size: 62.5%;
    font-size: 10px;

    @include for-phone-only {
        font-size: 9px;
    }

}

body {
    font-size: 16px;
    font-size: 1.6rem;
    height: 100vh;
    line-height: 1.45;

    > #root {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }
}
  /* @media (max-width: 700px) {
    body {
        font-size: 14px;
        font-size: 1.4rem;
    }
  } */

a, .link {
    text-decoration: none;
    color: $color_accent;
    cursor: pointer;

    &:hover {
        color: darken($color_accent, 20);
    }
    &:active,
    &:focus {
        color: darken($color_accent, 10);
        outline: none;
    }
    &.active {
        color: $color_dark;
        // text-decoration: underline;
    }
}
.clickable {
    cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
    color: $color_dark;
    font-weight: $font_weight_bold;
    line-height: 1.07;
    margin-top: 0;
    margin-bottom: 0.5em;
}
h1 { font-size: 6.0rem; }
h2 { font-size: 4.0rem; }
h3 { font-size: 3.5rem; }
h4 { font-size: 3.0rem; }
h5 { font-size: 2.8rem; }
h6 { font-size: 2.4rem; }

.top_wrapper .title{
    color: white;
}
.top_wrapper summary {
    color: white;
    font-weight: $font_weight_bold;
}

// section > h2:before,
// article > h2:before {
//     content: '';
//     display: block;
//     height: 1px;
//     width: 60px;
//     background-color: $color_accent;
//     margin-bottom: $spacing_s;
// }

b, .bold { font-weight: 600; }

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.color_body {
    color: $color_body;
}

@include for-phone-up {
    .linebreak-m::before {
        content: '\A';
        white-space: pre;
    }
}

.align_right {
    float: right;
}


/******************************************************/


section, article {
    width: 100%;
}
summary {
    // font-size: 2rem;
    font-weight: bold;
    margin-top: $spacing_xs;
    margin-bottom: $spacing_xs;
}
p {
    padding-bottom: $spacing_xs;
}

.page-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.top_wrapper {
    margin-top: -8rem;
    min-height: 80rem;
    background-image: url("./img/start_header.svg");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    position: relative;
    margin-bottom: 8rem;

    .particle-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0%;
    }

    .content-wrapper {
        margin-top: 20rem;
        max-width: $width_menu + $spacing_s *2;
        // margin-bottom: 23rem;
    }

    @include for-phone-up {
        .title { font-size: 6.5rem; }
        summary {
            width: 85%;
            padding-bottom: $spacing_s;
        }
        .top_graphics {
            width: 40%;
            // height: 0;
            // overflow: visible;
            z-index: 1;
            img {
                margin-top: -5rem;
                // margin-left: -30%;
                // max-width: calc(100% + 30% + 25px);
                // width: calc(100% + 30% + 25px);
                max-width: calc(100% + 60px);
                width: calc(100% + 60px);
            }
        }
    }

    @include for-phone-only {
        display: flex;
        align-items: center;
        min-height: calc(100vh - 11rem - 1rem + 8rem);

        .content-wrapper {
            margin-top: 10rem;
        }
    }
}

.content-wrapper {
    // outline: 1px dashed lightblue;  // FOR DEVELOPMENT //
    width: 100%;
    max-width: $width_content + $spacing_s *2;
    margin: $spacing_l*2 auto;
    padding: 0 $spacing_s;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.small {
        max-width: $width_content_small + $spacing_s *2;
    }
    &.wide {
        max-width: $width_menu + $spacing_s *2;
    }
    @include for-phone-only {
        margin: $spacing_l auto;
        padding: 0 $spacing_xs;
    }

    img {
        width: 100%;
        max-width: 100%;
        display: block;
    }

    &>.title {
        width: 100%;
        text-align: center;

        @include for-tablet-up {
            &.left {
                text-align: unset;
            }
        }
    }

    .card {
        // outline: 1px dashed yellowgreen; // FOR DEVELOPMENT //
        background-color: $white;
        margin-top: $spacing_l;
        margin-bottom: $spacing_l;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        @include box-shadow;

        .image {
            // border: 1px dashed red; // FOR DEVELOPMENT //
            height: 33rem;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .content {
            padding: $spacing_s $spacing_xs;
            display: flex;
            flex-direction: column;
        }
        h5, .title {
            display: flex;
            div {
                margin-top: -1rem
            }
            .count {
                font-size: 5.6rem;
                color: $color_accent_second;
                margin-right: $spacing_xs;
            }
            div + div {
                align-self: flex-end;
                min-height: 4rem;
            }
        }

        &.small {
            width: 48%;
            border-bottom: 4px solid $color_accent;
            margin-top: $spacing_s;
            margin-bottom: $spacing_s;
        }

        @include for-phone-up {
            .content {
                padding: $spacing_l;
            }
            button, .button {
                margin-top: auto;
                align-self: flex-start;
            }
            &.horizontal {
                width: 80%;
                height: 33rem;
                flex-direction: row;
                .image {
                    flex: 1 0 50%;
                    max-width: 45rem;
                }
            }
            &.vertical {
                width: 48%;
                max-width: 53rem;
                .image {
                    height: 33rem;
                }
            }

            &.small {
                .content {
                    padding: $spacing_l $spacing_m $spacing_s;
                }
            }
        }

        @include for-tablet-up {
            &.small {
                width: 31%;
                min-height: 28rem;
                &:last-of-type:nth-of-type(3n+2) {
                    margin-right: auto;
                    margin-left: 3.5%;
                }
            }
        }
    }
    .card.horizontal + .card.horizontal {
        margin-left: auto;
    }
    .card.horizontal + .title,
    .card.vertical + .title,
    .card.horizontal + .card.vertical,
    .card.vertical + .card.horizontal {
        padding-top: $spacing_l;
    }



    &.background-plate {
        background-color: #f2f2ff;

        @include for-phone-up {
            max-width: $width_content + $spacing_l *6;
            margin: $spacing_l auto;
            padding: $spacing_l $spacing_l *3;
            border-radius: 24px;
        }

        .card {
            box-shadow: unset;
            background-color: unset;
        }
        // border-radius: 8px;
        // margin: #{$spacing_l} #{-$spacing_l*3};
        // padding: $spacing_l $spacing_l*3;
        // width: calc(100% + #{$spacing_l * 6} );
        // max-width: 100vw;
    }
    .iframe-video-container{
        overflow: hidden;
        /* 16:9 aspect ratio */
        padding-top: 56.25%;
        position: relative;
        border-radius: 1.6rem;
        background-color: #d8d8d8;

        iframe {
            border: 0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
         }
    }

    .row {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        .column {
            width: 48%;
        }
        .column33 {
            width: 33%;
        }
        .column67 {
            width: 65%;
        }

        .middle {
            margin-top: auto;
            margin-bottom: auto;
        }

        @include for-phone-only {
            flex-direction: column;
            &.m-row-reverce {
                flex-direction: column-reverse;
            }
            .column, .column33, .column67 {
                width: 100%;
                padding-bottom: $spacing_xs;
            }
        }
    }


    @include for-phone-up {
        &.center {
            text-align: center;
            h2:before {
                margin-left: auto;
                margin-right: auto;
            }
            .row {
                justify-content: center;
            }
        }
    }
}

.quote {
    color: $color_dark;
    font-weight: $font_weight_bold;
    min-height: 5rem;
    padding-left: 5rem;
    padding-top: $spacing_xs;
    &::before{
        content: "";
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        position: absolute ;
        margin-top: 1.6rem;
        margin-left: -5rem;
        background-color: $color_accent;
    }
}

.shadow {
    @include box-shadow;
}

input, textarea, select, button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.button,
button,
input[type='button'] {
    color: $white !important;
    font-family: Lato;
    font-weight: bold;
    font-size: 1.6rem;
    min-width: 14rem;
    line-height: normal;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    padding: 1.1rem $spacing_m 1.4rem;
    border-radius: 8px;
    // box-shadow: 0 3px 1.5rem -1px rgba(0, 0, 0, 0.2);
    border: unset;
    outline: none;
    text-decoration: none;
    cursor: pointer;

    transition: background-color 0.25s;
    background-color: #5c68fd;
    background-image: linear-gradient($color_accent, #4351fa00);

    &:hover {
        background-color: darken($color_accent, 10);
    }
    &:active {
        background-color: darken($color_accent, 20);
    }

    &.white {
        color: $color_accent !important;
        background-color: $white;
        background-image: none;
        box-shadow: none;
        &:hover {
            background-image: linear-gradient(#f1f1f1, #f1f1f100);
        }
    }
    &.inverted {
        color: $color_accent !important;
        border: solid 2px $color_accent;
        background-color: transparent;
        background-image: none;
        box-shadow: none;
        padding: 0.9rem $spacing_m 1.2rem;
    }

    @include noselect;
    @include for-phone-only {
        font-size: 2rem;
    }
}


.form_wrapper {
    display: block;
    width: 100%;
    margin-left: auto;
    padding: $spacing_l $spacing_xl;
    border-radius: 8px;
    border: solid 1px $color_border;
    background-color: $white;
    @include box-shadow;

    @include for-phone-up {
        max-width: 55rem;
        margin: auto;
    }
    @include for-phone-only {
        padding: $spacing_m $spacing_xs ($spacing_s -$spacing_xs);
        button {
            width: 100%;
        }
    }

}


input[type='text'],
input[type='email'],
input[type='password'],
textarea {
    width: 100%;
    font-size: 1.6rem;
    font-family: $font;
    color: $color_dark;
    line-height: 1.45;
    margin: 0 0 $spacing_xs;
    padding: $spacing_xs/2 $spacing_xs;
    border-radius: 8px;
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    outline: none;
    transition: background-color 0.2s;

    &:hover {
        background-color: darken(#f2f2f2, 1);
    }
    &:focus {
        border: 1px solid $color_accent;
        background-color: darken(#f2f2f2, 0);
    }
    &.error {
        border: 1px solid $color_error;
    }
}
textarea {
    resize: none;
    min-height: calc(#{4 * 1.6rem * 1.45} + #{$spacing_xs});  /* <-- number of lines * font-size * lineheight + padding */
}

input[type='checkbox'],
input[type='radio'] {
    width: 2.4rem;
    height: 2.4rem;
    vertical-align: middle;

    display: inline-block;
    border: 1px solid darken(#f2f2f2, 20);
    background-color: #f2f2f2;
}
input[type='checkbox']:checked,
input[type='radio']:checked {
    background: $color_accent;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0ibm9uemVybyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTc0NyAtNTQ2KSB0cmFuc2xhdGUoNjc1IDE3NikgdHJhbnNsYXRlKDcyIDM3MCkiPgogICAgICAgIDxwYXRoIGQ9Ik05Ljg2OSAxOGMtLjI4LS4wMS0uNTUtLjEyLS43My0uMzE4bC00Ljg1Ni01LjE0LS4wMS0uMDFjLS4zOC0uNDA5LS4zNi0xLjAzNS4wNC0xLjQxMy40LS4zNzggMS4wMjktLjM1OCAxLjQwOS4wNGw0LjExNyA0LjM2NSA4LjQwMy05LjE0OGgtLjAxYy4zNC0uNDM3Ljk3LS40OTcgMS40LS4xNTkuNDI5LjMzOC40ODkuOTY1LjE1IDEuMzkybC0uMDguMDgtOS4xMzQgOS45NDN2LS4wMWMtLjE5LjE5OS0uNDYuMzE4LS43My4zMjhsLjAzLjA1eiIvPgogICAgPC9nPgo8L3N2Zz4K);
    background-position: center;
    background-repeat: no-repeat;
}

.form-message {
    padding-bottom: $spacing_s;
    .error {
        color: $color_error;
    }
    .success {
        color: $color_success;
        font-weight: $font_weight_bold;
    }
}

.notfound {
    padding-top: $spacing_l*3;
    text-align: center;
}
