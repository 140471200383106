@import "../../vars.scss";

.loader {
    text-align: center;
    padding: $spacing_s 0;
    overflow: hidden;

    > div {
        display: inline-block;
        position: relative;
        width: 72px;
        height: 13px;

        div {
            position: absolute;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background: #ddd;
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
        }
        div:nth-child(1) {
            left: 8px;
            animation: lds-ellipsis1 0.8s infinite;
        }
        div:nth-child(2) {
            left: 8px;
            animation: lds-ellipsis2 0.8s infinite;
        }
        div:nth-child(3) {
            left: 32px;
            animation: lds-ellipsis2 0.8s infinite;
        }
        div:nth-child(4) {
            left: 56px;
            animation: lds-ellipsis3 0.8s infinite;
        }
        @keyframes lds-ellipsis1 {
            0% {
                transform: scale(0);
            }
            100% {
                transform: scale(1);
            }
        }
        @keyframes lds-ellipsis3 {
            0% {
                transform: scale(1);
            }
            100% {
                transform: scale(0);
            }
        }
        @keyframes lds-ellipsis2 {
            0% {
                transform: translate(0, 0);
            }
            100% {
                transform: translate(24px, 0);
            }
        }
    }
}
button, .button {
    .loader {
        padding: 0;

        > div {
            height: 10px;
            div {
                width: 10px;
                height: 10px;
            }
        }

        > div {
            div {
                background: #fffc;
            }
        }
    }
    &.inverted {
        .loader > div div {
            background: rgba($color_accent, 0.8);
        }
    }
}

.small .loader {
    padding: 1rem;
}
