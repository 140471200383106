@import "../../../vars.scss";
$dotsize: 70%;

.radioButtonContainer {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 1em;
    pointer-events: all;
    outline: none;
    cursor: pointer;
    color: $bright_grey;
    transition-duration: 200ms;

    &:hover {
        color: $dark_grey;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    .radioButtonTitle {
        flex: 1;
        margin-left: 1em;
    }

    .radioButton {
        position: relative;
        min-width: 24px;
        min-height: 24px;
        background-color: $white;
        border-radius: 50%;
        border: solid 2px $bright_grey;
        box-sizing: border-box;
        overflow: hidden;

        &:hover {
            border-color: $color_accent;
        }

        .dot {
            content: "";
            position: absolute;
            width: $dotsize;
            height: $dotsize;
            background-color: $color_accent;
            left: 50%;
            top: 50%;
            translate: transformX(100%);
            margin-left: 100%;
            margin-top: calc(#{$dotsize} / -2);
            border-radius: 50%;
            transition-duration: 200ms;
        }

        .selected {
            transition-delay: 50ms;
            margin-left: calc(#{$dotsize} / -2);
        }
    }

    .selected {
        border-color: $color_accent;
        color: $dark_grey;
        font-weight: 600;
    }
}
