@import '../../vars.scss';

.sidebarItem {
    position: relative;
    color: $dark_grey;
    margin-bottom: 1em;
    padding-bottom: 1em;

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        display: inline-block;
        width: 50%;
        height: 1px;
        background-color: $color_border;
    }

    .header {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 0.5em;
        display: flex;
        align-items: center;
    }

    .statusBanner {
        width: 24px;
        height: 8px;
        border-radius: 4px;
        margin-left: 1em;
    }

    .amount {
        font-size: 25px;
        font-weight: bold;
        color: $color_accent;
    }

    .textWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;
    }

    .text {
        font-size: 1.6rem;
        color: $grey_text;
        padding: 0;
        margin: 0;
    }

    .colorBarWrapper {
        width: 100%;
    }

    .colorBar {
        position: relative;
        width: 100%;
        height: 24px;
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid $color_border;

        .marker {
            background-color: #fff;
            width: 4px;
            height: 24px;
            position: absolute;
            top: 0;
        }
    }
}