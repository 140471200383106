$white: white;
$black: black;
$dark_grey: #2d333a;
$bright_grey: #96999D;
$grey_text: #575c61;


$gray: #909090;
$color_dark: #1d225a;

$color_background: #f9f9f9;
$color_menu: #fff;
$color_body: #9194af;

$color_accent: #4351fa;
$color_accent_second: #dcddff;
$color_border: #e0dbdb;

$color_success: #62af62;
$color_error: #ef6262;

$font: "Lato", sans-serif;
// $font_bold: "Lato-Black", sans-serif;
$font_weight: 300;
$font_weight_bold: 900;

$width_menu: 134.0rem;
$width_content: 124.0rem; //was 144
$width_content_small: 88.0rem; //??


$spacing_xs: 1.6rem; //??
$spacing_s:  2.4rem; //??
$spacing_m:  3.2rem; //??
$spacing_l:  5.0rem;
$spacing_xl:  8.0rem; //??

$z-index_header:  100;


@mixin box-shadow {
    box-shadow: 0 26px 60px -18px rgba(0, 0, 0, 0.1);
}

@mixin noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

/*
@mixin hover {
    -webkit-transition: opacity 0.2s;
    -moz-transition:    opacity 0.2s;
    -o-transition:      opacity 0.2s;
    transition:         opacity 0.2s;
    &:hover,
    &:focus {
        opacity: 0.8;
    }
    &:active {
        opacity: 0.6;
    }

    &:disabled {
        opacity: 0.65;
        cursor: not-allowed;
        pointer-events: none;
    }
}
*/


/* Example on how to use it:
    .site-header {
        @include for-phone-only {
            font-size: 2.4rem;
        }
    }
*/

@mixin for-phone-only {
    @media (max-width: 600px) {
        @content;
    }
}
@mixin for-phone-up {
    @media (min-width: 601px) {
        @content;
    }
}
@mixin for-tablet-only {
    @media (max-width: 900px) {
        @content;
    }
}
@mixin for-tablet-up {
    @media (min-width: 901px) {
        @content;
    }
}
@mixin for-tablet-down {
    @media (max-width: 901px) {
        @content;
    }
}
@mixin for-desktop {
    @media (min-width: 1100px) {
        @content;
    }
}
