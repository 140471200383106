@import "../../vars.scss";

#maps {
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;

    .overlay {
        position: absolute;
        padding: 2em;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        left: 0;
        top: 0;
        background-color: transparent;
        pointer-events: none;

        .companyLogo {
            width: 89px;
            height: 32px;
        }

        @include for-phone-only {
            .mapButtons {
                bottom: 16rem !important;    
            }
        }

        .mapButtons {
            position: absolute;
            bottom: 2.4rem;
            left: 2.4rem;

            & > div {
                margin-bottom: 12px;
            }
            
            .buttonContainer {
                display: flex;
                flex-direction: row;
                align-items: center;
                
                .errorMessage {
                    color: #fff;
                    padding: 0;
                    margin: 0 8px;
                    
                }

                .mapButton {
                    width: 36px;
                    height: 36px;
                    border-radius: 8px;
                    box-shadow: 0 2px 12px 1px rgba(0, 0, 0, 0.4);
                    border: solid 1px rgba(0, 0, 0, 0.2);
                    background-color: #ffffff;
                    background-image: none;
                    padding: 0;
                    min-width: auto;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    pointer-events: all;
                    transition-duration: 250ms;
                    transition-property: background-color;

                    &:disabled {
                        background-color: #c9c9cf;
                    }

                    &:hover {
                        background-color: #e6e8fe;
                    }

                    &:active {
                        background-color: #d2d6fe;
                    }
                }
            }
        }

        .back-button {
            text-align: left;
            padding-left: 0;
            background-color: transparent;
            border: transparent;
            align-self: flex-start;
            color: $white;
            background-image: none;
            min-width: 7em;
            box-shadow: none;
            pointer-events: all;
        }

        .zoomErrorWrapper {
            position: absolute;
            bottom: 2.4rem;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 8px;
            background-color: #fff;
            padding: $spacing_xs;
            color: #000;
        }

        .roughnessColorBar {
            position: absolute;
            top: 50%;
            right: $spacing_s;
            transform: translateY(-50%);
            width: 56px;
            height: auto;
            border-radius: 8px;
            box-shadow: 0 2px 12px 1px rgba(0, 0, 0, 0.4);
            border: solid 1px $color_border;
            background-color: #fff;
            pointer-events: all;

            .headerText {
                font-size: 1.2rem;
                color: $grey_text;
                padding: 0.8rem 0;
                text-align: center;
                margin: 0;

                &.border {
                    border-bottom: solid 1px $color_border;
                    margin: 0 0.6rem;
                }
            }

            .colorBarWrapper {
                display: flex;
                height: 272px;
                
                .colorBar {
                    width: 24px;
                    height: 100%;
                    border-radius: 0 4px 4px 0;
                    margin-right: 4px;
                }
    
                .textWrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    height: 100%;

                    .text {
                        font-size: 1.4rem;
                        color: $grey_text;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
    .mapContainer {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        .mapSearchWrapper {
            position: absolute;
            left: $spacing_s;
            top: 100px;
            width: 200px;

            input[type="text"] {
                background-color: $color_menu;
            }
        }
    }
}
