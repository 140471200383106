@import "../../vars.scss";

.userInitials {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 56px;
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: $spacing_s;
    border-radius: 8px;
    box-shadow: 0 2px 12px 1px rgba(0, 0, 0, 0.4);
    border: solid 1px rgba(0, 0, 0, 0.2);
    background-color: $color_menu;
    cursor: pointer;
    pointer-events: all;
    
    h6 {
        font-size: 20px;
        margin-bottom: 0;
        color: $dark_grey;
    }
}

.userContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .userInfo {
        border-bottom: 1px solid $color_border;
        padding-bottom: 1.6rem;

        p {
            padding: 0;
            margin-top: 0;
            margin-bottom: 0.8rem;
        }

        .usernameText {
            font-weight: bold;
        }

        .emailText {
            font-size: 1.4rem;
            color: $dark_grey;
        }
    }

    .userActions {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .userLink {
        font-size: 1.4rem;
        align-self: flex-start;
        padding: 0;
    }

    .logoutButton {
        align-self: flex-start;
        cursor: pointer;
        color: $color_accent;
        font-weight: bold;
        margin-bottom: $spacing_xl;
    }
}